import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import config from "../../../data/SiteConfig";
import "./Thanks.scss";
import disableBrowserBackButton from 'disable-browser-back-navigation';

class Thanks extends Component {

  componentDidMount(){
    disableBrowserBackButton();
  }

  render() {
    return (
      <div className="md-grid mobile-fix">
        <Card className="md-grid md-cell--14" >
          <div className="thanks-wrapper">
        
            <span className="material-icons">check_circle</span>
            <p>&nbsp;</p>
            <p>Submission Successful !</p>
            <p>Thank you for submitting your trade-in request</p>
          </div> 
        </Card>
      </div>
    );
  }
}

export default Thanks;
